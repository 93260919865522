<template>
  <v-container>
    <h2>{{ $t("navbar.faq.title") }}</h2>
    <div>{{ $t("navbar.faq.subtitle") }}</div>

    <div v-if="content != null">
      <v-row>
        <v-col cols="12" md="4" sm="6" v-for="item in content" :key="item.id">
          <v-card
            elevation="3"
            class="faq-card"
            :to="{ name: 'FaqList', params: { categoryName: item.slug } }"
          >
            <v-flex class="d-flex">
              <v-avatar tile>
                <img :src="item.immagine.guid" alt="Immagine categoria" />
              </v-avatar>
              <div>
                <v-card-title
                  ><strong v-html="item.name"></strong
                ></v-card-title>
                <v-card-subtitle v-html="item.description"></v-card-subtitle>
              </div>
            </v-flex>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import CMSService from "~/service/cmService";
export default {
  name: "FaqCategory",
  jsonld() {
    if (this.content) {
      var items = this.content.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        url: process.env.VUE_APP_EBSN_URL + "/faq/" + item.slug
      }));
      return {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: items
      };
    }
  },
  data() {
    return {
      content: null
    };
  },
  created() {
    this.fetchPage();
  },
  methods: {
    async fetchPage() {
      const res = await CMSService.getCustomPostByFilters("categoria_faq");

      if (res && res.data && res.data.length) {
        this.content = res.data;
      }
      console.log(res);
    }
  }
};
</script>
<style scoped>
img.size-full {
  width: 100%;
}
.v-avatar {
  margin: 5px;
  margin-left: 15px;
  height: 65px !important;
  min-width: 65px !important;
  width: 65px !important;
  top: 14px;
}
.faq-card {
  min-height: 100px;
  height: 100%;
  border-radius: 8px;
}
.v-card__subtitle {
  line-height: 1.3em;
  padding-top: 6px;
}
.v-card__title {
  font-size: 1.05rem;
  line-height: 1.2em;
  word-break: break-word;
}
</style>
